<template>
  <section>
    <div class="container">
      <div class="d-flex justify-content-center align-items-center min-vh-100">
        <div class="text-center">
          <img :src="$imageFilters('/images/Logo_02.svg')" alt="logo" class="opacity-75 mb-2" />
          <h1 class="mb-7 text-primary">404 找不到頁面</h1>
          <router-link to="/" class="btn btn-primary text-white px-5">回到首頁</router-link>
        </div>
      </div>
    </div>
  </section>
</template>
